import { Link } from "gatsby";
import React from "react";
import ImgixFluidImage from "./ImgixFluidImage";
import PlaceHolderImage from "./placeHolderImage";

const FeaturedCard = ({image, title, subtitle, href, ar, sizes = "100vw"}) => {

  const finalImage = image ? <ImgixFluidImage attribution={image.attribution} imageUrl={image.url} sizes={sizes} ar={ar||4/2} focalPoint={image.focalPoint} /> : <PlaceHolderImage/>;

  return (
    <li className="box box--featured box--secondary swiper-slide">
      <div className="box__inner">
        <Link to={href} className="box__link-main"/>
        <figure className="box__image image-fit js-image-fit">
						<span className="box__image-text">Featured</span>
            {finalImage}
			  </figure>
        <div className="box__body">
          <p className="box__title h6">{title}</p>
          <div className="box__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}/>
          <div className="box__link link-more text-gray-3">Read more</div>
        </div>
      </div>
  </li>
  );
}

export default FeaturedCard;