import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Card from "../components/newComponents/card";
import SectionHead from "../components/newComponents/sectionHead";
import FeaturedCard from "../components/newComponents/featuredCard";
import Pagination from "../components/newComponents/pagination";

export const query = graphql`
  query NewsPostsQuery($limit: Int, $skip: Int) {

    entry: craftNewsIndexNewsIndexEntry {
      title
      displayTitle
      summary
    }

    news: allCraftNewsNewsEntry(sort: {fields: postDate, order: DESC}, limit: $limit, skip: $skip) {
      nodes {
        displayTitle
        slug
        uri
        postDate
        summary
        featureImage {
          ...AssetFragment
        }
      }
    }
  }
`;

const News = ({data: {entry, news}, pageContext}) => (
  <Layout pageContext={pageContext}>
    <Seo title={entry.title} />
    <div className="main">
      <section className="section-boxes section-boxes--news section-boxes--news-secondary">
        <div className="shell">
          <SectionHead title="Genesis in the news" subtitle="NEWS & INSIGHTS" />
          <div className="boxes boxes--news swiper-container js-slider-boxes-news">
            <ul>
              {pageContext.currentPage === 1 &&
                <FeaturedCard title={news.nodes[0].displayTitle} subtitle={news.nodes[0].summary} href={`/${news.nodes[0].uri}`} image={news.nodes[0].featureImage[0]}/>
              }
              {news.nodes.slice(pageContext.currentPage === 1 ? 1 : 0).map((item, i) => (
                <Card key={`news-listing-${i}`} image={item.featureImage[0]} title={item.displayTitle} subtitle={item.summary} href={`/${item.uri}`}/>
              ))}
            </ul>
            <div className="swiper-pagination boxes__pagination" />
          </div>
          {pageContext.totalPages > 1 &&
            <Pagination nextUrl={pageContext.nextUrl} prevUrl={pageContext.prevUrl} currentPage={pageContext.currentPage} base="/news" totalPages={pageContext.totalPages}/>
          }
        </div>
      </section>
    </div>
  </Layout>
);

export default News;